






.wrap-all {
  position: relative;
  display: grid;
  place-content: center;
  
  position: relative;
  background: #1F1F1F;
  border-radius: 6px;
  top: 0vh;
  height: 100%;
  min-height: 51.5em;
  overflow: hidden;
  width: 90vw;
  /* width: 90vw;
  margin: 0 auto; */
  /*transform-style: preserve-3d;
  transform-origin: 50% 100%;
  transform: rotateX(30deg);*/
}

.wrap-turn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 86vw;
}

.page {
  
  background: #FFFFF6;
  border: solid 1px #aeaf9c;
  height: fit-content;
  min-height: 50em;
  box-sizing: border-box;
  padding: 10px;

}



.cover:before {
  content: '';
  display: block;
  background: #1F1F1F;
  border-radius: 5px;
  top: -2px;
}

.edge {
  position: absolute;
  background: #FFFFF6;
  background: repeating-linear-gradient(to right,
      #FFFFF6,
      #383826 1px,
      #FFFFF6 5px,
      #383826 1px);
  top: 1.4vh;
  border: solid 1px #aeaf9c;
  min-height: 50em;
  box-sizing: border-box;
  padding: 10px;
}

.edge-left {
  right: 88vw;
  width: 3vw;
  transform-origin: 100% 50%;
  transform: rotateY(-60deg);
}

.edge-right {
  left: 88vw;
  width: 2vw;
  transform-origin: 0 50%;
  transform: rotateY(50deg);
}

.left {
  background: linear-gradient(to right, #FFFCEF 0%, #FFFCEF 95%, #9e9d8b 98%, #383826 100%);
  border-right: solid 1px #383826;
  transform-origin: 100% 50%;
  border-top-right-radius: 50px 5px;
  border-bottom-right-radius: 50px 3px;
  padding: 2.3em;
}

.right {
  background: linear-gradient(to left, #FFFCEF 0%, #FFFCEF 95%, #9e9d8b 98%, #383826 100%);
  border-left: solid 1px #383826;
  transform-origin: 0 50%;
  border-top-left-radius: 50px 5px;
  border-bottom-left-radius: 50px 3px;
  padding: 2.3em;
}



.wrap-curr {
  transform-origin: 0 50%;
}

#current {
  transform-origin: 0 50%;
  text-align: center;
}

.wrap-prev {
  transform-origin: 100% 50%;
}


.wrap-back {
  position: absolute;
  overflow: hidden;
  top: -3.5vh;
  right: 0;
  width: 0;
  height: 90vh;
  transform-origin: 100% 50%;
  background: linear-gradient(to right, #FFFCEF 0%, #FFFCEF 95%, #9e9d8b 98%, #383826 100%);
  box-shadow: 25px 0 16px 4px rgba(0, 0, 0, 0.5), inset 0px 0 18px 0px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(-60px 8px 15px rgba(0, 0, 0, .5));
}

#back {
  /* //height: 100%; */
  mix-blend-mode: overlay;
  background: none;
  border-left: solid 1px #383826;
  border-right: solid 1px #383826;
  transform-origin: 100% 50%;
}